<template>
  <case-container
    :pagination="pagination"
    @changed="handlePaginationChanged"
  >
    <template slot="filter">
      <case-back-div
        v-if="false"
        :to="backTo"
      />
      <route-breadcrumb />
      <case-uploads-filter
        :case-id="caseId"
        :pagination="pagination"
        :filter="filter"
        :missed-term="missedTerm"
        :subjects="caseActors"
        @changed="handleFilterChanged"
        @created="loadCaseUploads"
      >
        <div
          v-if="showBulkActions"
          slot="actions"
          class="mx-3"
          style="margin-top: 0.65rem"
        >
          <el-dropdown
            type="primary"
            class="dropdown-actions dropdown-actions-simple text-primary-300"
            popper-class="select-primary"
            @command="handleBulkCommand"
          >
            <div class="font-weight-bold">
              Actions
              <ChevronDownIcon />
            </div>
            <el-dropdown-menu
              slot="dropdown"
              type="primary"
              class="dmenu select-primary bg-filter-chip"
              dark
            >
              <el-dropdown-item command="export">
                Export
              </el-dropdown-item>
              <el-dropdown-item command="status">
                Update Status
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </case-uploads-filter>
    </template>
    <base-select-table
      v-if="isTable"
      v-model="selected"
      class="card-table"
      :columns="columns"
      :data="caseUploadSummaries"
    >
      <!-- eslint-disable-next-line vue/no-template-shadow -->
      <template v-slot:data-name="{ data }">
        <div class="d-flex align-items-center">
          <StarIcon
            class="mr-3 mt-1 flex-shrink-0 cursor-pointer"
            :class="{
              'text-muted': !data.item.uploadStarred,
              'text-primary': data.item.uploadStarred,
              'fill-primary': data.item.uploadStarred,
            }"
            size="20"
            @click="() => toggleStarred(data.item)"
          />
          <div>
            <div
              class="font-weight-bold"
              @click="viewTranscript(data.item)"
            >
              {{ data.item.name || "welcome" }}
            </div>
            <div class="d-flex align-items-center">
              <p class="text-lightblue mb-0 text-truncate">
                {{ data.item.originalFileName || "welcome.wav" }}
              </p>
              <InfoIcon
                size="16"
                class="ml-1 text-lightblue"
              />
            </div>
          </div>
        </div>
      </template>
      <!-- eslint-disable-next-line vue/no-template-shadow -->
      <template v-slot:data-uploadedOn="{ data }">
        {{
          $date(data.item?.uploadMetadata?.createdOn)
            .tz(userTimezone)
            .format("MMM DD, YYYY")
        }}
      </template>
      <!-- eslint-disable-next-line vue/no-template-shadow -->
      <template v-slot:data-startTimeStamp="{ data }">
        {{
          $date(data.item.startTimeStamp)
            .tz(userTimezone)
            .format("MMM DD, YYYY - hh:mm a")
        }}
      </template>


      <!-- eslint-disable-next-line vue/no-template-shadow -->
      <template v-slot:data-clipsCount="{ data }">
        {{
          clipsCount(data.item)
        }}
      </template>
    </base-select-table>
    <template v-else-if="hasData">
      <div :key="hasData">
        <case-upload-item
          v-for="cus in caseUploadSummaries"
          :key="cus.createdOn + cus.uploadId + cus.status"
          :case-upload-summary="cus"
          :disable-actions="showBulkActions"
          :selected="selected.filter((s) => s.uploadId === cus.uploadId).length === 1"
          @selected="handleSelected"
          @export="showExportModal"
          @status="showStatusModal"
        />
      </div>
    </template>
    <transcript-export-modal
      ref="exportModal"
      @export="handleExport"
    />
    <clip-status-modal
      ref="clipStatusModal"
      :upload-ids="selected.map((s) => s.uploadId)"
      @updated="() => clearSelected(true)"
      @close="clearSelected"
    />
    <loading-modal
      ref="loadingModal"
      copy="Downloading"
      additional-copy="This may take a few moments"
    />
    <download-blob
      ref="dlblob"
      :blob="dlFileBlob"
      :file-name="dlFileName"
      :content-type="dlFileContentType"
      :visible="false"
    />
  </case-container>
</template>

<script>
import {
  deleteCaseUploadsSummary,
  getCaseUploadSummariesPaged,
  putUpload,
  downloadUpload,
  getUploadById,
} from "../../../api";
import {isDefined} from "../../../api/helpers";
import {
  DEFAULT_UPLOADS_DIRNAME,
  EMPTY_SEARCH_WRAPPER,
} from "../../../util/consts";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {filterToQuery, getRecentCaseUploads} from "../../../util/util";
import {ethosRouteNames, ethosRouteNames as names} from "../../../routes/routeNames";
import CaseContainer from "../../../components/DashboardV2/Case/CaseContainer.vue";
import CaseBackDiv from "../../../components/DashboardV2/Case/CaseBackDiv.vue";
import CaseUploadsFilter from "../../../components/DashboardV2/Case/CaseUploadsFilter.vue";
import CaseUploadItem from "../../../components/DashboardV2/Case/CaseUploadItem.vue";
import {computed} from "vue";
import BaseSelectTable from "../../../components/DashboardV2/BaseSelectTable.vue";
import {InfoIcon, StarIcon, ChevronDownIcon} from "vue-feather-icons";
import TranscriptExportModal from "../../../components/DashboardV2/Case/TranscriptExportModal.vue";
import {buildExportUploadContent, downloadUploadTranscript, downloadZip, ExportType, exportZip} from "../../../util/exportUtil";
import DownloadBlob from "../../Ethos/DownloadBlob.vue";
import LoadingModal from "../../../components/LoadingModal.vue";
import ClipStatusModal from "../../../components/DashboardV2/Case/ClipStatusModal.vue";
export default {
  components: {
    CaseContainer,
    CaseBackDiv,
    CaseUploadsFilter,
    CaseUploadItem,
    BaseSelectTable,
    InfoIcon,
    StarIcon,
    ChevronDownIcon,
    TranscriptExportModal,
    DownloadBlob,
    LoadingModal,
    ClipStatusModal,
  },
  data() {
    return {
      accord: [],
      selected: [],
      recentUploadsSelected: [],
      newUploadsSelected: [],
      allUploadsSelected: [],
      workflows: [],
      loading: false,
      processingWorkflows: false,
      isTable: false,
      columns: [
        {
          value: "name",
          label: "Upload Name",
          class: "",
        },
        {
          value: "status",
          label: "Status",
        },
        {
          value: "uploadType",
          label: "Type",
        },
        {
          value: "uploadedOn",
          label: "Uploaded On",
          class: "text-center",
        },
        {
          value: "clipsCount",
          label: "Clips",
          class: "text-center",
        },
        {
          value: "startTimeStamp",
          label: "Event Date / Time",
          class: "text-center",
        },
      ],
      exportData: null,
      dloading: false,
      dlFileBlob: null,
      dlFileName: null,
      dlFileContentType: null,
    };
  },
  provide() {
    return {
      isTable: computed(() => this.isTable),
      changeListStyle: () => {
        this.isTable = !this.isTable;
        localStorage.caseUploadView = this.isTable ? "table" : "card";
      },
    };
  },
  watch: {
    uploadsListRefreshToken(to, from) {
      this.loadCaseUploads();
    },
    getWorkflowResponse(to, from) {
      const workflows = to.filter(
        (x) =>
          !isDefined(x["__intervalId"]) &&
          ["CreateTranscription", "Create Transcription"].includes(x.workflowId)
      );
      if (workflows.length > 0) {
        this.setWorkflowResponses();
      }
    },
    dloading(to) {
      if (this.$refs.loadingModal) {
        this.$refs.loadingModal.showModal = to;
      }
    },
  },
  computed: {
    ...mapGetters("data", [
      "getCaseUploadSummaries",
      "getCaseActorAttributions",
      "uploadsListRefreshToken",
      "getWorkflowResponse",
      "userTimezone",
      "getActorMap",
      "getUserMap",
    ]),
    backTo() {
      return ethosRouteNames.DashboardV2;
    },
    caseId() {
      const id = this.$route.params.caseId;
      return isDefined(id) ? parseInt(id, 10) : null;
    },
    showBulkActions() {
      return this.selected && this.selected.length > 1;
    },
    caseUploadSummariesPaged() {
      const emptySearchWrapper = Object.assign({}, EMPTY_SEARCH_WRAPPER);
      if (!isDefined(this.getCaseUploadSummaries) || !isDefined(this.caseId)) {
        return emptySearchWrapper;
      }

      return this.getCaseUploadSummaries[this.caseId] || emptySearchWrapper;
    },
    caseUploadSummaries() {
      return this.caseUploadSummariesPaged.data;
    },
    hasData() {
      return Array.isArray(
        this.caseUploadSummaries
      ) && this.caseUploadSummaries.length > 0 ? new Date().toISOString() : false;
    },
    pagination() {
      return this.caseUploadSummariesPaged.pagination;
    },
    filter() {
      return this.caseUploadSummariesPaged.filter;
    },
    missedTerm() {
      return this.caseUploadSummariesPaged.missedTerm;
    },
    dirs() {
      return this.caseUploadSummaries.reduce((a, ces) => {
        if (a.indexOf(ces.dir) === -1) a.push(ces.dir);
        return a;
      }, []);
    },
    recentUploads() {
      const ids = getRecentCaseUploads(this.caseId);
      return this.caseUploadSummaries.filter(
        (ces) => ids.indexOf(`${ces.uploadId}`) !== -1
      );
    },
    recentTableData() {
      return this.mapCusToTable(this.recentUploads);
    },
    recentSelectedUploads() {
      const tdata = this.recentTableData;
      return this.recentUploadsSelected.map((i) => tdata[i].data);
    },
    recentSelectedUploadsIds() {
      return this.recentSelectedUploads.map((ces) => ces.uploadId);
    },
    newUploads() {
      return this.caseUploadSummaries.filter(
        (ces) => ces.status === "New"
      );
    },
    newTableData() {
      return this.mapCusToTable(this.newUploads);
    },
    newSelectedUploads() {
      const tdata = this.newTableData;
      return this.newUploadsSelected.map((i) => tdata[i].data);
    },
    newSelectedUploadsIds() {
      return this.newSelectedUploads.map((ces) => ces.uploadId);
    },
    uploadByDir() {
      return this.caseUploadSummaries.reduce((a, cus) => {
        let dirName = cus.dir;
        if (!isDefined(dirName) || dirName.length === 0) {
          dirName = DEFAULT_UPLOADS_DIRNAME;
        }
        if (isDefined(a[dirName])) {
          a[dirName].push(cus);
        } else {
          a[dirName] = [cus];
        }
        return a;
      }, {});
    },
    allTableData() {
      return Object.entries(this.uploadByDir)
        .map(([key, value]) => {
          return {
            name: key,
            // createdBy: "Cannot know",
            // createdOn: "Mar 22, 2023 at 12:00 PM",
            caseUploads: value,
            caseUploadsCount: value.length,
            exhibits: value.reduce((a, c) => a + c.clipCount, 0),
            signals: value.reduce((a, c) => a + c.incidentCount, 0),
            people: value.reduce(
              (a, c) => a + c.actorAttributionCount + c.userAttributionCount,
              0
            ),
          };
        })
        .sort((a, b) => (a.name === DEFAULT_UPLOADS_DIRNAME ? 1 : -1));
    },
    allSelectedUploads() {
      const tdata = this.allTableData;
      return this.allUploadsSelected.map((i) => tdata[i].data);
    },
    allSelectedUploadsIds() {
      return this.allSelectedUploads.map((ces) => ces.uploadId);
    },
    viewCaseRoute() {
      return names.DashboardV2;
    },
    caseActors() {
      if (!isDefined(this.caseId) || !isDefined(this.getCaseActorAttributions)) {
        return [];
      }
      return this.getCaseActorAttributions[this.caseId] ?? [];
    },
  },
  mounted() {
    this.loadCaseUploads();
    try {
      this.mountLoadCaseUploadPeople();
    } catch (ex) {
      // silence
    }
  },
  methods: {
    ...mapMutations("data", [
      "putCaseUploadSummaries",
      "popWorkflowResponse",
      "setWorkflowResponse",
      "putCaseUploadSummary",
    ]),
    ...mapActions("data", [
      "loadPeople",
      "loadCasePeopleAttributions",
    ]),
    ...mapActions([
      "pollWorkflow",
    ]),
    mountLoadCaseUploadPeople() {
      if (isDefined(this.caseId) && this.caseActors.length === 0) {
        this.loadCasePeopleAttributions({caseId: this.caseId}).catch((ex) => {
        // Silent
        });
      }
    },
    loadCaseUploads() {
      if (!isDefined(this.caseId)) return;
      this.loading = true;
      getCaseUploadSummariesPaged(
        this.caseId,
        this.pagination.CurrentPage,
        this.pagination.PageSize,
        filterToQuery(this.filter)
      )
        .then((response) => {
          if (!isDefined(response) || !isDefined(response.data)) throw new Error("Unable to get CaseUploadsPaged");
          const workflowResponse = this.getWorkflowResponse;
          const newWorkflows = response.data.reduce((acc, cus) => {
            const t = cus.workflows.filter((w) =>
              ["CreateTranscription", "Create Transcription"].includes(
                w.workflowId
              )
            );
            if (t.length === 0) return acc;
            if (
              -1 ===
              workflowResponse.findIndex(
                (w) => w.workflowPersistenceId == t.workflowPersistenceId
              )
            ) {
              t[0].uploadName = cus.name;
              acc.push(t[0]);
            }
            return acc;
          }, []);

          this.setupWorkflowPolls(newWorkflows);
          this.setWorkflowResponses();
          this.putCaseUploadSummaries({
            caseId: this.caseId,
            ...response,
          });
        })
        .catch((ex) => {
          this.$notifyError("Failed to gather Uploads for Case", ex);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    mapCusToTable(cuss) {
      if (!isDefined(cuss) || !Array.isArray(cuss) || cuss.length === 0) {
        return [];
      }
      return cuss.map((cus) => {
        return {
          name: cus.name,
          data: cus,
          createdBy: cus.uploadMetadata.createdBy,
          createdOn: cus.uploadMetadata.createdOn,
          exhibits: cus.clipCount,
          signals: cus.incidentCount,
          people: cus.actorAttributionCount + cus.userAttributionCount,
          workflows: cus.workflows,
        };
      });
    },
    reset(key) {
      this[`${key}UploadsSelected`] = [];
      this.loadCaseUploads();
    },
    deleteCaseUploads(key) {
      const uploadIds = this[`${key}SelectedUploadsIds`].slice();
      const requests = uploadIds.map((eid) =>
        deleteCaseUploadsSummary(this.caseId, eid)
      );
      Promise.allSettled(requests)
        .then((r) => {
          this.$notifySuccess("Removed Uploads from Case");
        })
        .catch((ex) => {
          this.$notifyError("Unable to delete an Uploads from the Case", ex);
        })
        .finally(() => {
          this.reset(key);
        });
    },
    setupWorkflowPolls(workflows) {
      for (let i = 0; i < workflows.length; i++) {
        const found = this.getWorkflowResponse.find(
          (x) => x.workflowPersistenceId == workflows[i].workflowPersistenceId
        );
        if (!found) {
          const workflowPayload = {
            id: workflows[i].id,
            uploadId: workflows[i].uploadId,
            uploadName: workflows[i].uploadName,
            name: workflows[i].workflowId,
            status: "In Progress",
            progress: 2,
            workflowId: workflows[i].workflowId,
            workflowPersistenceId: workflows[i].workflowPersistenceId,
          };
          this.setWorkflowResponse(workflowPayload);
        }
      }
    },
    setWorkflowResponses() {
      const workflowResponse = this.getWorkflowResponse;
      for (let x = 0; x < workflowResponse.length; x++) {
        if (
          !["Create Transcription", "CreateTranscription"].includes(
            workflowResponse[x].name
          )
        ) {
          continue;
        }
        const workflow = workflowResponse[x];
        if (workflowResponse[x].status === "Complete") {
          this.popWorkflowResponse(workflow.workflowPersistenceId);
          continue;
        }
        if (workflowResponse[x]["__intervalId"]) {
          continue;
        }
        const poll = this.pollWorkflow;
        const pop = this.popWorkflowResponse;
        const interv = window.setInterval(() => {
          poll(workflowResponse[x])
            .then((finished) => {
              this.uploading = !finished;
              if (finished) {
                window.clearInterval(interv);
                window.setTimeout(() => {
                  pop(workflowResponse[x].workflowPersistenceId);
                }, 3000);
              }
            })
            .catch((ex) => {});
        }, 10 * 1000);
        this.$nextTick(() => {
          const wp = workflowResponse[x];
          wp["__intervalId"] = interv;
          this.setWorkflowResponse(wp);
        });
      }
    },
    handlePaginationChanged(newPagination) {
      this.putCaseUploadSummaries({
        caseId: this.caseId,
        pagination: newPagination,
      });
      this.loadCaseUploads();
    },
    handleFilterChanged(newFilter) {
      this.putCaseUploadSummaries({
        caseId: this.caseId,
        filter: newFilter,
      });
      this.loadCaseUploads();
    },
    viewTranscript(cus) {
      this.$router.push({
        name: ethosRouteNames.TranscriptV2,
        params: {
          caseId: cus.caseId,
          uploadId: cus.uploadId,
        },
      });
    },
    clipsCount(cus) {
      return Math.max(cus.clipCount, (cus.clips || []).length);
    },
    async doPutUpload(cus, remark, starred) {
      return await putUpload(
        cus.uploadId,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        remark,
        starred
      );
    },
    toggleStarred(cus) {
      if (this.loading) return; // Ignore.
      this.loading = true;
      this.doPutUpload(cus, undefined, !cus.uploadStarred).then((model) => {
        this.putCaseUploadSummary(
          {
            caseId: cus.caseId,
            data: Object.assign({}, cus, {uploadStarred: model.starred}),
          });
      }).catch((ex) => {
        this.$notifyError("Unable to update favorite status", ex);
      }).finally(() => {
        this.loading = false;
      });
    },
    handleSelected(data) {
      if (data) {
        if (data.selected) {
          if (this.selected.findIndex((s) => s.uploadId === data.data.uploadId) === -1) {
            this.selected.push(data.data);
          }
        } else {
          this.selected = this.selected.filter((s) => s.uploadId !== data.data.uploadId);
        }
      }
    },
    handleBulkCommand(command) {
      if (!this.selected || this.selected.length <= 1) return;
      switch (command) {
        case "export":
          this.$refs.exportModal.showModal = true;
          break;
        case "status":
          this.$refs.clipStatusModal.display();
          break;
        default: break;
      }
    },
    showExportModal(data) {
      this.exportData = data;
      this.$refs.exportModal.showModal = true;
    },
    showStatusModal(data) {
      this.$refs.clipStatusModal.display(data && data.status ? data.status : null, data.uploadId);
    },
    handleExport(data) {
      if (!isDefined(this.exportData)) {
        if (this.selected && this.selected.length > 1) {
          return this.handleBulkExport(data, this.selected.map((s) => s.uploadId));
        }
        return;
      }
      if (data.type === ExportType.audio) {
        this.downloadOriginal(this.exportData.uploadId);
      } else {
        this.downloadTranscript(this.exportData.uploadId, data.type, data.withAudio);
      }
    },
    handleBulkExport(data, uploadIds) {
      if (this.dloading) return;
      this.dloading = true;
      this.loadPeople()
        .then(() => Promise.all(uploadIds.map((id) => getUploadById(id, true))))
        .then((uploads) => {
          return uploads.map((u) => {
            return {upload: u};
          });
        })
        .then((contents) => {
          if (data.type === ExportType.audio || data.withAudio) {
            return Promise.all(contents
              .map((c) => downloadUpload(c.upload.id, true)
                .then((audio) => {
                  return {upload: c.upload, audio};
                })
              )
            );
          }
          return contents;
        })
        .then((contents) => contents.map(
          (c) => buildExportUploadContent(c, data.type, this.getUserMap, this.getActorMap))
        )
        .then(exportZip)
        .then((zipBlob) => {
          if (zipBlob) {
            downloadZip(zipBlob);
          }
        })
        .catch((ex) => {
          this.$notifyError("Unable to export files", ex);
        })
        .finally(() => {
          this.dloading = false;
        });
    },
    downloadTranscript(uploadId, type, withAudio) {
      if (this.dloading) return;
      this.dloading = true;
      downloadUploadTranscript(
        this.loadPeople,
        getUploadById, downloadUpload,
        this.getUserMap, this.getActorMap,
        uploadId, type, withAudio
      )
        .catch((ex) => {
          this.$notifyError("Unable to download file", ex);
        })
        .finally(() => {
          this.dloading = false;
          this.exportData = null;
        });
    },
    downloadOriginal(uploadId) {
      if (this.dloading) return;
      this.dloading = true;
      getUploadById(uploadId).then((upload) => {
        this.dlFileName = upload.originalFileName;
        this.dlFileContentType = upload.originalFileContentType;
        return downloadUpload(uploadId, true); // TRUE for original
      }).then((blob) => {
        this.dlFileBlob = blob;
        this.$nextTick(() => {
          this.$notifySuccess("Downloading to file");
          this.$refs.dlblob.directDownload();
        });
      }).catch((ex) => {
        this.$notifyError("Unable to download file", ex);
      }).finally(() => {
        this.dloading = false;
        this.exportData = null;
      });
    },
    clearSelected(shouldReload) {
      this.selected = [];
      if (shouldReload === true) {
        this.loadCaseUploads();
      }
    },
  },
  beforeMount() {
    this.isTable = localStorage.caseUploadView == "table";
  },
};
</script>

<style lang="scss">
.evidence-sub--list {
  position: relative;
  .evidence-divider {
    border: 1px solid #608a8a;
    height: 100%;
    width: 0px;
    position: absolute;
    top: 0;
    left: 60px;
  }
}
</style>
