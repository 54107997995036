<template>
  <div class="w-100 mb-4">
    <div
      v-if="!buttonOnly"
      ref="dropzone"
      :class="buttonClasses"
      :loading="uploading"
      @dragenter="areaDragEnter"
      @dragover="areaDragOver"
      @dragleave="areaDragLeave"
      @drop="areaDrop"
    >
      <template v-if="!centerButton">
        <upload-cloud-icon
          size="1.5x"
          class="mr-3"
        />
        Drag &amp; Drop
      </template>

      <base-button
        v-if="centerButton"
        type="primary"
        simple
        :loading="uploading"
        @click="areaClick"
      >
        {{ centerButtonText }}
      </base-button>
    </div>
    <base-button
      v-if="!centerButton"
      type="primary"
      simple
      :loading="uploading"
      @click="areaClick"
    >
      <upload-cloud-icon
        size="1.5x"
        class="mr-3"
      />
      Upload
    </base-button>
    <input
      ref="uploadinput"
      type="file"
      :accept="acceptedFileTypes"
      style="display: none"
      multiple
      @change="upload"
    >
  </div>
</template>

<script>
import {UploadCloudIcon} from "vue-feather-icons";

export default {
  name: "UploadFile",
  components: {UploadCloudIcon},
  props: {
    types: {
      type: String,
      default: null,
    },
    buttonType: {
      type: String,
      default: "primary",
    },
    buttonOnly: {
      type: Boolean,
      default: false,
    },
    centerButton: {
      type: Boolean,
      default: false,
    },
    centerButtonText: {
      type: String,
      default: "Upload",
    },
  },
  data() {
    return {
      dragover: false,
      uploading: false,
      imageUrl: null,
      progress: 0,
    };
  },
  computed: {
    buttonClasses() {
      return `btn btn-${this.$props.buttonType} btn-simple btn-file btn-file-ethos m-0 w-100`;
    },
    acceptedFileTypes() {
      return this.$props.types;
    },
  },
  methods: {
    areaDragEnter: function(e) {
      e.preventDefault();
      this.dragover = true;
    },
    areaDragOver: function(e) {
      e.preventDefault();
      this.dragover = true;
    },
    areaDragLeave: function(e) {
      e.preventDefault();
      this.dragover = false;
    },
    areaDrop: function(e) {
      e.preventDefault();
      this.dragover = false;
      if (this.uploading) {
        return; // TODO: - show error?
      }
      if (
        e.dataTransfer &&
        e.dataTransfer.files &&
        e.dataTransfer.files.length > 0
      ) {
        this.doUpload(e.dataTransfer.files[0]);
        return;
      }
      // TODO: - show error?
    },
    areaClick: function(e) {
      e.preventDefault();
      if (!this.uploading) {
        // Setting to null so that same file can be re-uploaded
        this.$refs.uploadinput.value = null;
        this.$refs.uploadinput.click();
      }
    },
    // We need to make it able to upload multiple files here
    upload: function(e) {
      e.preventDefault();
      if (this.uploading) {
        return; // TODO: - show error?
      }
      if (e.target && e.target.files && e.target.files.length > 0) {
        // Goes through each file in files to do upload
        for (let i = 0; i < e.target.files.length; i++) {
          const file = e.target.files[i];
          if (file) this.doUpload(file);
        }
        return;
      }
      // TODO: - show error?
    },
    doUpload: function(file) {
      const reader = new FileReader();
      if (file.name.lastIndexOf(".") <= 0) {
        return;
      }

      this.uploading = true;
      reader.readAsDataURL(file);

      reader.onprogress = (event) => {
        if (event.lengthComputable) {
          this.progress = event.total > 0 ? event.loaded / event.total : 0;
        }
      };
      reader.onload = (event) => {
        this.uploading = false;
        this.doEmit(file);
      };
      reader.onabort = (event) => {
        this.uploading = false;
      };
      reader.onerror = (event) => {
        this.uploading = false;
      };
    },
    doEmit: function(file) {
      // We are adding a new field (Date) to allow the unsort of A-Z later
      if (file) {
        file.ethos = new Date();
        this.$emit("filesSelected", file);
      }
    },
  },
};
</script>

<style>
.btn-file-ethos {
  width: 200px;
  height: 100px;
  border-style: dashed;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
  font-weight: 200;
}
.btn-file-ethos:hover {
  border-style: solid;
}
.btn-file-ethos i {
  margin-right: 8px;
}
</style>
