<template>
  <div>
    <case-back-div
      to="CaseUploads"
      title="Back to All Uploads"
    />
    <div class="d-flex p-5 items-center align-items-center">
      <div>
        <h6 class="text-white mb-0">
          {{ dirNameDisplay }}
        </h6>
        <p
          v-if="false"
          class="mb-0 text-lightblue"
        >
          Created by Jim Smith on Mar 00, 0000
        </p>
      </div>
      <div class="flex-grow-1" />
      <base-button
        type="simple"
        class="m-0"
      >
        <PlusIcon class="mr-3" />
        Transcript
      </base-button>
    </div>
    <div class="px-5">
      <case-uploads-list-table
        v-model="selected"
        :data="tableData"
        @clicked="handleRowClicked"
      >
        <case-uploads-actions
          v-if="selected.length > 0"
          slot="tableheader"
          class="d-inline pl-4"
          @move="() => $refs.dirModal.showModal = true"
          @delete="deleteCaseUploads"
        />
      </case-uploads-list-table>
      <case-uploads-dir-modal
        ref="dirModal"
        :case-id="caseId"
        :upload-ids="selectedUploadIds"
        :dirs="dirs"
        @close="reset"
      />
    </div>
  </div>
</template>

<script>
import {PlusIcon} from "vue-feather-icons";
import {mapGetters, mapMutations} from "vuex";
import {deleteCaseUploadsSummary, getCaseClips, getCaseUploadsSummaries} from "../../../api";
import {isDefined} from "../../../api/helpers";
import BaseButton from "../../../components/BaseButton.vue";
import CaseBackDiv from "../../../components/DashboardV2/Case/CaseBackDiv.vue";
import CaseUploadsActions from "../../../components/DashboardV2/Case/CaseUploadsActions.vue";
import CaseUploadsDirModal from "../../../components/DashboardV2/Case/CaseUploadsDirModal.vue";
import CaseUploadsListTable from "../../../components/DashboardV2/Case/CaseUploadsListTable.vue";
import {DEFAULT_UPLOADS_DIRNAME} from "../../../util/consts";

export default {
  components: {
    CaseBackDiv,
    BaseButton,
    PlusIcon,
    CaseUploadsListTable,
    CaseUploadsActions,
    CaseUploadsDirModal,
  },
  data() {
    return {
      loading: false,
      selected: [],
      expandedUploadsIds: [],
    };
  },
  computed: {
    ...mapGetters("data", [
      "getCaseUploadSummaries",
      "getCaseClipSummaries",
      "getWorkflowResponse",
    ]),
    caseId() {
      const id = this.$route.params.caseId;
      return isDefined(id) ? parseInt(id, 10) : null;
    },
    caseUploadsSpecificSummaries() {
      if (!isDefined(this.getCaseUploadSummaries) || !isDefined(this.caseId)) return [];

      const cses = this.getCaseUploadSummaries[this.caseId];
      this.getWorkflowResponse.forEach((item) => {
        const cseIndex = cses.findIndex((cse) => cse.uploadId === item.uploadId &&
          ["CreateTranscription", "Create Transcription"].includes(item.workflowId));
        if (cseIndex !== -1) {
          cses[cseIndex]["__status"] = item.status;
          cses[cseIndex]["__progress"] = item.progress;
        }
      });
      return [...cses];
    },
    dirName() {
      const dir = this.$route.params.dirName;
      return isDefined(dir) ? dir : "";
    },
    dirNameDisplay() {
      return this.dirName === "" ? DEFAULT_UPLOADS_DIRNAME : this.dirName;
    },
    dirs() {
      return this.caseUploadsSpecificSummaries.reduce((a, ces) => {
        if (a.indexOf(ces.dir) === -1) a.push(ces.dir);
        return a;
      }, []);
    },
    caseUploadSummaries() {
      return this.caseUploadsSpecificSummaries.filter((ces) => ces.dir === this.dirName || (this.dirName === DEFAULT_UPLOADS_DIRNAME && ces.dir === ""));
    },
    selectedUploads() {
      const tdata = this.tableData;
      return this.selected.map((i) => i);
    },
    selectedUploadIds() {
      return this.selectedUploads.map((ces) => ces.uploadId);
    },
    tableData() {
      const nestedDataLoading = !this.caseClipSummariesLoaded;
      return this.caseUploadSummaries.map((cse) => {
        const showNested = this.expandedUploadsIds.indexOf(cse.uploadId) !== -1;
        const nestedData = this.caseClipSummaries.filter((c) => c.uploadId === cse.uploadId);
        return {
          name: cse.name,
          data: cse,
          signals: cse.incidentCount,
          exhibits: cse.clipCount,
          createdOn: cse.uploadMetadata.createdOn,
          showNested,
          nestedData,
          nestedDataLoading,
          workflows: cse.workflows,
        };
      });
    },
    caseClipSummariesLoaded() {
      const summaries = this.getCaseClipSummaries;
      return isDefined(summaries) &&
        isDefined(this.caseId) &&
        isDefined(summaries[this.caseId]);
    },
    caseClipSummaries() {
      return this.caseClipSummariesLoaded ? this.getCaseClipSummaries[this.caseId] : [];
    },
  },
  mounted() {
    if (this.caseUploadSummaries.length === 0) {
      this.loadCaseUploads();
    }
  },
  methods: {
    ...mapMutations("data", ["putCaseUploadSummaries", "putCaseClipSummaries"]),
    loadCaseUploads() {
      if (!isDefined(this.caseId)) return;
      this.loading = true;
      getCaseUploadsSummaries(this.caseId).then((response) => {
        this.putCaseUploadSummaries({
          caseId: this.caseId,
          summaries: response,
        });
      }).catch((ex) => {
        this.$notifyError("Failed to gather Uploads for Case", ex);
      }).finally(() => {
        this.loading = false;
      });
    },
    reset() {
      this.selected = [];
      this.loadCaseUploads();
    },
    deleteCaseUploads() {
      const uploadIds = this.selectedUploadIds.slice();
      const requests = uploadIds.map((eid) => deleteCaseUploadsSummary(this.caseId, eid));
      Promise.allSettled(requests).then((r) => {
        this.$notifySuccess("Removed Upload from Case");
      }).catch((ex) => {
        this.$notifyError("Unable to delete an Upload from the Case", ex);
      }).finally(() => {
        this.reset();
      });
    },
    loadClips() {
      getCaseClips(this.caseId).then((response) => {
        this.putCaseClipSummaries({caseId: this.caseId, summaries: response});
      }).catch((ex) => {
        this.$notifyError("Unable to load Exhibits", ex);
      });
    },
    handleRowClicked(e) {
      const item = e.item;
      const index = e.index;
      if (!item || !isDefined(index)) return;
      if (!this.caseClipSummariesLoaded) {
        this.loadClips();
      }
      if (this.expandedUploadsIds.indexOf(item.data.uploadId) === -1) {
        this.expandedUploadsIds = [...this.expandedUploadsIds, item.data.uploadId];
      } else {
        this.expandedUploadsIds = this.expandedUploadsIds.filter((e) => e != item.data.uploadId);
      }
    },
  },
};
</script>

<style></style>
